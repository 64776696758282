<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-notebook-edit</v-icon>SSCC発行</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="updateForm" v-model="validUpdateForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-select
                label="運送会社"
                :items="TruckingCompanyList"
                v-model="ssccModel.selectedCompany"
                :error-messages="truckingCompanyErrorMessages"
                :readonly="isRe"
                filled
                dense
                attach
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="送り状NO"
                v-model="ssccModel.invoiceNumber"
                type="number"
                :rules="[rules.numberRule, rules.maxLength(12)]"
                :error-messages="invoiceNumberErrorMessages"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="SSCC"
                v-model="ssccModel.sscc"
                type="number"
                :rules="[rules.numberRule, rules.maxLength(20)]"
                :error-messages="ssccErrorMessages"
                :readonly="true"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onSerialShippingContainerCodeSubmit">
        {{ isRe ? "再発行" : "発行" }}
      </v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import TruckingCompany from "../../consts/TruckingCompany";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "SerialShippingContainerCode",
  props: ["inputModel"],
  filters: {},
  components: {},
  data() {
    return {
      TruckingCompanyList: TruckingCompany.all(),
      ssccModel: null,
      exportFilePath: null,
      validUpdateForm: null,
      updateModel: {},
      rules: {
        maxLength: Validation.maxLength,
        numberRule: (value) => this.numberRules(value),
      },
      validEntryForm: null,
      purchaseStatus: null,
      truckingCompanyErrorMessages: [],
      invoiceNumberErrorMessages: [],
      ssccErrorMessages: [],
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isRe() {
      return Boolean(this.inputModel.sscc);
    },
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    init(inputModel) {
      this.initSSCCModel(inputModel);
      this.resetError();
      if (this.$refs.updateForm) this.$refs.updateForm.resetValidation();
    },
    reset() {
      this.init(this.inputModel);
    },
    async initSSCCModel(inputModel) {
      this.ssccModel = {
        cartonId: inputModel.cartonId,
        sscc: inputModel.sscc,
        selectedCompany: inputModel.truckingCompany,
        invoiceNumber: inputModel.invoiceNumber,
        lastUpdateDatetime: inputModel.updateDate,
      };
    },
    async onSerialShippingContainerCodeSubmit() {
      this.resetError();
      if (this.validate()) {
        try {
          this.loadingOn();
          let condition = [];
          condition.push({
            cartonId: this.ssccModel.cartonId,
            selectedCompany: this.ssccModel.selectedCompany,
            invoiceNumber: this.ssccModel.invoiceNumber,
            lastUpdateDatetime: this.ssccModel.lastUpdateDatetime,
          });
          const ssccResponse = await this.$store.dispatch("amazon/sscc", { exportConditions: condition });
          console.log("SerialShippingContainerCode::onSerialShippingContainerCodeSubmit", ssccResponse);
          switch (ssccResponse.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.exportFilePath = ssccResponse.data.contents.exportFilePath;
              window.open(this.exportFilePath, "_blank");
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = this.setError(ssccResponse.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: `SSCC発行`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              var messages = ssccResponse.data.header.messages;
              var alreadyChangedMessage = [];
              for (let kye in messages) {
                alreadyChangedMessage.push(messages[kye].join("<br>"));
              }
              this.$dialog.warning({
                title: `SSCC発行`,
                text: alreadyChangedMessage.join("<br>"),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("SerialShippingContainerCode::onSerialShippingContainerCodeSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
          this.$emit("created");
        }
      }
    },
    onCancelClick() {
      this.$emit("cancel");
      this.reset();
    },
    validate() {
      const isValid = this.$refs.updateForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    numberRules(value) {
      if (value == null) return true;
      if (isNaN(value)) return "数字を入力してください";
      return true;
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      var isMessage = false;
      if (responseMessage?.truckingCompany) {
        isMessage = true;
        this.truckingCompanyErrorMessages = responseMessage.truckingCompany;
      }
      if (responseMessage?.invoiceNumber) {
        isMessage = true;
        this.invoiceNumberErrorMessages = responseMessage.invoiceNumber;
      }
      if (responseMessage?.sscc) {
        isMessage = true;
        this.ssccErrorMessages = responseMessage.sscc;
      }
      if (!isMessage) {
        Object.keys(responseMessage).forEach((key) => {
          messages.push(responseMessage[key].join("<br>"));
        });
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
    resetError() {
      this.truckingCompanyErrorMessages = [];
      this.invoiceNumberErrorMessages = [];
      this.ssccErrorMessages = [];
    },
  },
};
</script>
