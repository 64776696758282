<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-format-list-bulleted-square</v-icon>予約リスト</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch">
              <dp-date-picker
                type="date"
                v-model="searchModel.importDateFrom"
                :rules="[rules.importDateFromRule]"
                label="取込日From"
                dense
              ></dp-date-picker>
              <dp-date-picker
                type="date"
                v-model="searchModel.importDateTo"
                label="取込日To"
                :rules="[rules.importDateToRule]"
                dense
              ></dp-date-picker>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button icon="mdi-database-import" @click="onImportClick" v-if="allowedAction(['C080102'])"
            >新商品情報リスト取込</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <amazon-new-product-import
              v-if="importDialog.show"
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
            ></amazon-new-product-import>
          </v-dialog>
          <tooltip-icon-button
            v-if="allowedAction(['C080103'])"
            icon="mdi-book-arrow-down"
            @click="onReservationExportClick"
            :disabled="selectionRows.length === 0"
            >予約リスト出力</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="allowedAction(['C080104'])"
            icon="mdi-file-download"
            @click="onRBSIssueClick"
            :disabled="selectionRows.length === 0"
            >RBSリスト発行</tooltip-icon-button
          >
          <v-divider vertical></v-divider>
          <tooltip-icon-button v-if="allowedAction(['C080105'])" icon="mdi-download" @click="onCsvExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 100%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="reserveRecords"
          class="ag-theme-alpine"
          style="height: 98%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="ReservationSearchInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <reservation-search-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></reservation-search-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { NumericColumn, DateColumn, FullDateColumn } from "../../models/ag-grid/columnTypes";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import AmazonNewProductImport from "../../components/amazon/NewProductImport.vue";
import ReservationSearchInfos from "./../../components/amazon/ReservationSearchInfos.vue";

export default {
  name: "ReservationSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    AmazonNewProductImport,
    ReservationSearchInfos,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: this.globalPageSize,
      rules: {
        importDateFromRule: (value) => this.importDateFromRules(value),
        importDateToRule: (value) => this.importDateToRules(value),
      },
      validSearchForm: null,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpNumericColumn: NumericColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "JANCODE", field: "janCode", pinned: "left" },
          { headerName: "ASIN", field: "asin" },
          { headerName: "Title", field: "title" },
          { headerName: "Vendor Code", field: "vendorCode" },
          { headerName: "締切日", field: "orderClosingDate", type: "dpDateColumn" },
          { headerName: "発売日", field: "releaseDate" },
          {
            headerName: "縦(cm)",
            field: "depth",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
          },
          {
            headerName: "横(cm)",
            field: "width",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
          },
          {
            headerName: "高さ(cm)",
            field: "height",
            type: "numericColumn",
            filter: "agNumberColumnFilter",
          },
          {
            headerName: "予約数",
            field: "reservationQuantity",
            type: "dpNumericColumn",
          },
          {
            headerName: "新商品情報リスト取込番号",
            field: "importNumber",
            hide: true,
          },
        ],
        rowSelection: "single",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: this.globalPageSize,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
      },
      reserveRecords: [],
      selectionRows: [],
      searchModel: { importDate: null },
      importDialog: { show: false, width: "800px" },
      detailColumns: [
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "ASIN", field: "asin" },
        { headerName: "Title", field: "title" },
        { headerName: "Vendor Code", field: "vendorCode" },
        { headerName: "締切日", field: "orderClosingDate", type: "dpFullDateColumn" },
        { headerName: "発売日", field: "releaseDate" },
        {
          headerName: "縦(cm)",
          field: "depth",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "横(cm)",
          field: "width",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "高さ(cm)",
          field: "height",
          type: "numericColumn",
          filter: "agNumberColumnFilter",
        },
        {
          headerName: "予約数",
          field: "reservationQuantity",
          type: "dpNumericColumn",
        },
      ],
    };
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      return getDisplayDetails2(selectedRows[0].id, this.detailColumns, this.gridOptions.api);
    },
  },
  watch: {
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("amazon/searchReservation", this.searchModel);
        console.log("ReservationSearch::onSearchClick", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "予約リスト",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.reservations).length === 0) {
          this.$dialog.warning({
            title: "予約リスト",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        // this.gridOptions.api.setRowData(result.orders);
        this.gridOptions.api.setRowData(result.reservations);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("ReservationSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    onImportClick() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      console.log("ReservationSearch::onImportComplete", "canceled", canceled);
      if (!canceled) this.onSearchClick();
    },
    async onReservationExportClick() {
      if (this.selectionRows[0].importNumber) {
        try {
          this.loadingOn();
          let exportConditions = { importNumber: this.selectionRows[0].importNumber };
          const response = await this.$store.dispatch("amazon/outReservationlist", { exportConditions });
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          var result = response.data.contents;
          window.open(result.exportFilePath, "_blank");
        } catch (error) {
          console.error("ReservationSearch::onReservationExportClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        console.error("ReservationSearch::onReservationExportClick 取り込み番号がありません");
      }
    },
    async onRBSIssueClick() {
      if (this.selectionRows[0].importNumber) {
        try {
          this.loadingOn();
          let exportConditions = { importNumber: this.selectionRows[0].importNumber };
          const response = await this.$store.dispatch("amazon/issueRbs", { exportConditions });
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          var result = response.data.contents;
          window.open(result.exportFilePath, "_blank");
        } catch (error) {
          console.error("ReservationSearch::onRBSIssueClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        console.error("ReservationSearch::onRBSIssueClick 取り込み番号がありません");
      }
    },
    onCsvExportClick() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        fileName: `予約リスト.csv`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    importDateFromRules(value) {
      if (value == null || this.searchModel.importDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.importDateTo)) return "取込日Toより前の日付を指定してください";
      return true;
    },
    importDateToRules(value) {
      if (value == null || this.searchModel.importDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.importDateFrom)) return "取込日Fromより後の日付を指定してください";
      return true;
    },
  },
};
</script>
