<template>
  <div :style="{ height: height }">
    <v-row v-if="isCafereoUser" style="background-color: white; z-index: 1; position: relative">
      <v-col v-if="isCafereoUser" cols="2">
        <v-card elevation="1" class="mt-4">
          <v-card-text class="font-weight-bold ml-4 mb-2">
            出荷数合計：{{ this.shipQuantityTotal | comma }}
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer
    ></v-row>
    <v-toolbar dense>
      <slot name="menu-title"></slot>
      <v-spacer></v-spacer>
      <slot name="menu-tools"></slot>
      <template v-if="preview">
        <tooltip-icon-button
          v-if="!hideCommit && allowedAction(['C080308'])"
          icon="mdi-lead-pencil"
          @click="onEditorClick"
          >編集開始</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="!hideCommit && allowedAction(['C080309'])"
          icon="mdi-close-circle"
          @click="onCloseClick"
          >非表示</tooltip-icon-button
        >
      </template>
      <template v-else>
        <tooltip-icon-button
          v-if="(!hideCommit && allowedAction(['C080305'])) || (hideCommit && allowedAction(['C080304']))"
          :disabled="selectionRows.length === 0"
          icon="mdi-basket"
          @click="onTransferClick(false)"
          >カートン移動</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="(!hideCommit && allowedAction(['C080305'])) || (hideCommit && allowedAction(['C080304']))"
          :disabled="selectionRows.length !== 1"
          icon="mdi-basket"
          @click="onTransferClick(true)"
          >数量指定カートン移動</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="!hidePurge && allowedAction(['C080306'])"
          icon="mdi-basket-remove"
          @click="onPurgeClick"
          >カートン削除</tooltip-icon-button
        >
        <tooltip-icon-button
          v-if="!hideCommit && allowedAction(['C080307'])"
          icon="mdi-basket-fill"
          @click="onCommitClick"
          >カートン確定</tooltip-icon-button
        >
      </template>
    </v-toolbar>
    <ag-grid-vue
      @grid-ready="onGridReady"
      :gridOptions="gridOptions"
      :rowData="records"
      class="ag-theme-alpine"
      style="height: 72%"
      @selection-changed="onSelectionChanged"
      @data-model-changed="$emit('change', $event)"
      :alwaysShowHorizontalScroll="true"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, DateColumn, IncludeFilter } from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import { comma } from "../../filter/NumberFilter";

export default {
  name: "CartonGrid",
  components: {
    AgGridVue,
    TooltipIconButton,
  },
  model: { prop: "records", event: "change" },
  props: {
    identifier: { type: String },
    suffix: { type: Number, default: 0 },
    records: { type: Array, default: () => [] },
    pageSize: { type: Number, default: 10 },
    preview: { type: Boolean, default: false },
    hideCommit: { type: Boolean, defaul: false },
    hidePurge: { type: Boolean, defaul: false },
    height: { type: String, default: "350px" },
  },
  data() {
    return {
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpNumericColumn: NumericColumn,
        },
        frameworkComponents: {},
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 55,
          },
          { headerName: "出荷日", field: "shipDate", filter: "agDateColumnFilter", type: "dpDateColumn", width: 125 },
          { headerName: "PO番号", field: "poNumber", width: 125, filterParams: IncludeFilter },
          { headerName: "JANCODE", field: "janCode", width: 150 },
          { headerName: "タイトル", field: "title", width: 150, filterParams: IncludeFilter },
          { headerName: "商品名", field: "productName", width: 300, filterParams: IncludeFilter },
          {
            headerName: "Amount",
            field: "orderQuantity",
            width: 120,
            type: "dpNumericColumn",
          },
          {
            headerName: "BX IN",
            field: "inBoxQuantity",
            width: 120,
            type: "dpNumericColumn",
          },
          {
            headerName: "BC IN",
            field: "inCtBoxQuantity",
            width: 120,
            type: "dpNumericColumn",
          },
          {
            headerName: "CP IN",
            field: "inCtPcsQuantity",
            width: 120,
            type: "dpNumericColumn",
          },
          { headerName: "配送先", field: "delivery1", width: 120 },
          { headerName: "社内備考", field: "cafereoRemarks", width: 150 },
          {
            headerName: "単価",
            field: "unitPrice",
            width: 130,
            type: "dpNumericColumn",
          },
          {
            headerName: "出荷ID",
            field: "shippingInstructionId",
            hide: true,
          },
          {
            headerName: "更新日",
            field: "updateDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            hide: true,
          },
        ],
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        suppressCellSelection: true,
        suppressColumnVirtualisation: true,
        pagination: true,
        paginationPageSize: this.globalPageSize,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
      },
      selectionRows: [],
      shipQuantityTotal: 0,
    };
  },
  filters: { comma },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.onCalcTotalCartonItem();
    },
    onTransferClick(amountFlg) {
      this.$emit("transfer", {
        suffix: this.suffix,
        rows: this.selectionRows,
        commit: (amount) => {
          if (amountFlg) {
            let updateRows = this.selectionRows;
            updateRows[0].orderQuantity = Number(updateRows[0].orderQuantity) - Number(amount);
            if (updateRows[0].orderQuantity === 0) {
              this.gridOptions.api.applyTransaction({ remove: updateRows });
            } else {
              this.gridOptions.api.applyTransaction({ update: updateRows });
            }
          } else {
            this.gridOptions.api.applyTransaction({ remove: this.selectionRows });
          }
        },
        amountFlg: amountFlg,
      });
      this.onCalcTotalCartonItem();
    },
    onPurgeClick() {
      this.$emit("purge", { suffix: this.suffix, rows: this.records });
    },
    onCommitClick() {
      if (this.gridOptions.api.paginationGetRowCount() !== 0) {
        this.$emit("commit", { suffix: this.suffix, rows: this.records });
      } else {
        this.$dialog.notify.error(`空のカートンは確定できません`, {
          timeout: 2300,
        });
      }
    },
    onEditorClick() {
      this.$emit("editor", { suffix: this.suffix, rows: this.records });
    },
    onCloseClick() {
      this.$emit("close", { suffix: this.suffix, rows: this.records });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
      this.onCalcTotalCartonItem();
    },
    updateGrid() {
      console.log(this.records);
      this.gridOptions.api.applyTransaction({ update: this.records });
    },
    onCalcTotalCartonItem() {
      console.log("onCalcTotalCartonItem");
      // 選択レコード毎の各合計を算出
      this.shipQuantityTotal = 0;
      for (let row of this.selectionRows) {
        if (this.isCafereoUser) {
          this.shipQuantityTotal += row.orderQuantity ? row.orderQuantity : 0;
        }
      }
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
    this.shipQuantityTotal = 0;
  },
};
</script>
