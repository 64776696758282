<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-truck</v-icon>Amazon出荷指示</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
            <search-conditions max-width="250px" @search="onBtnSearch">
              <dp-date-picker
                type="date"
                label="出荷日From"
                v-model="searchModel.shipDateFrom"
                :rules="[rules.shipDateFromRule]"
                dense
              ></dp-date-picker>
              <dp-date-picker
                type="date"
                label="出荷日To"
                v-model="searchModel.shipDateTo"
                :rules="[rules.shipDateToRule]"
                dense
              ></dp-date-picker>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C080402'])"
            icon="mdi-database-arrow-down"
            @click="onDataExportClick"
            >出荷指示データ出力</tooltip-icon-button
          >
          <v-dialog v-model="dataExportDialog" max-width="1200px" persistent>
            <shipping-instruction-data-export
              :isAmazon="true"
              :inputModel="selectionRows"
              @cancel="dataExportDialog = false"
              @exported="onDataExportSubmit"
              v-if="dataExportDialog"
            ></shipping-instruction-data-export>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="allowedAction(['C080403'])"
            icon="mdi-notebook-edit"
            @click="onSerialShippingContainerCodeClick"
            :disabled="!selectedRow"
            >SSCC発行(再発行)</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="allowedAction(['C080405'])"
            :disabled="selectionRows.length < 1"
            icon="mdi-close"
            @click="onBtnDelete"
            >SSCC削除</tooltip-icon-button
          >
          <v-dialog v-model="ssccDialog" max-width="1000px" persistent scrollable>
            <serial-shipping-container-code
              v-if="ssccDialog"
              :inputModel="selectedRow"
              @cancel="ssccDialog = false"
              @created="onIssueSscc"
            ></serial-shipping-container-code>
          </v-dialog>
          <tooltip-icon-button v-if="allowedAction(['C080404'])" icon="mdi-download" @click="onCsvExportClick"
            >CSVダウンロード</tooltip-icon-button
          >
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="instructionRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col
        v-if="shownInfo"
        id="ShippingInstructionInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <shipping-instruction-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></shipping-instruction-infos>
      </v-col>
    </v-row>
    <error-grid-dialog ref="errorGrid" width="80%" height="80%" :columns="deleteErrorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { NumericColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import SerialShippingContainerCode from "../../components/amazon/SerialShippingContainerCode.vue";
import ShippingInstructionInfos from "./../../components/amazon/ShippingInstructionInfos.vue";
import ShippingInstructionDataExport from "./../../components/stock/ShippingInstructionDataExport.vue";
import TruckingCompany from "../../consts/TruckingCompany";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "ShippingInstruction",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    SerialShippingContainerCode,
    ShippingInstructionInfos,
    ShippingInstructionDataExport,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpNumericColumn: NumericColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 55,
          },
          {
            headerName: "出荷日",
            field: "shipDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            pinned: "left",
          },
          { headerName: "倉庫", field: "delivery1" },
          { headerName: "カートン名", field: "cartonName" },
          {
            headerName: "レコード数",
            field: "recordCount",
            type: "dpNumericColumn",
          },
          { headerName: "SSCC", field: "sscc" },
          {
            headerName: "運送会社",
            field: "truckingCompany",
            filterParams: { options: TruckingCompany.all() },
            valueGetter: (params) => TruckingCompany.of(params.data.truckingCompany),
          },
          { headerName: "送り状NO", field: "invoiceNumber" },
          { headerName: "出荷ID", field: "cartonId", hide: true },
          {
            headerName: "更新日時",
            field: "updateDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            hide: true,
          },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.cartonId,
      },
      detailColumns: [
        {
          headerName: "出荷日",
          field: "shipDate",
          filter: "agDateColumnFilter",
          type: "dpDateColumn",
          pinned: "left",
        },
        {
          headerName: "倉庫",
          field: "delivery1",
        },
        { headerName: "カートン名", field: "cartonName" },
        {
          headerName: "レコード数",
          field: "recordCount",
          type: "dpNumericColumn",
        },
        { headerName: "SSCC", field: "sscc" },
        { headerName: "運送会社", field: "truckingCompany" },
        { headerName: "送り状NO", field: "invoiceNumber" },
      ],
      deleteErrorColmuns: [
        { headerName: "SSCC", field: "sscc" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      instructionRecords: [],
      selectionRows: [],
      searchModel: {
        shipDateFrom: null,
        shipDateTo: null,
      },
      dataExportDialog: false,
      ssccDialog: false,
      rules: {
        shipDateFromRule: (value) => this.shipDateFromRules(value),
        shipDateToRule: (value) => this.shipDateToRules(value),
      },
      validSearchForm: null,
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.cartonId, this.detailColumns, this.gridOptions.api);

      //return getDisplayDetails(this.selectedRow.cartonId, this.gridOptions.api, this.gridOptions.columnApi);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("amazon/searchShippinginstruct", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "Amazon出荷指示一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.shipments).length === 0) {
          this.$dialog.warning({
            title: "Amazon出荷指示一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.shipments);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.instructionRecords = result.shipments;
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    onDataExportClick() {
      let ssccWarningFlg = false;
      this.selectionRows.forEach((row) => {
        if (row.sscc) {
          ssccWarningFlg = true;
        }
      });
      if (ssccWarningFlg) {
        this.$dialog.warning({ text: `SSCC発行済みのレコードが含まれています。`, actions: ["OK"] });
      }
      this.dataExportDialog = true;
    },
    onDataExportSubmit(exportFilePath) {
      this.dataExportDialog = false;
      if (exportFilePath != null && exportFilePath != "") {
        window.open(exportFilePath, "_blank");
      }
    },
    onDataExportCancel() {
      this.dataExportDialog = false;
    },
    onSerialShippingContainerCodeClick() {
      this.ssccDialog = true;
    },
    async onBtnDelete() {
      try {
        this.loadingOn();
        const deleteList = {
          removeConditions: this.selectionRows.map((r) => ({
            cartonId: r.cartonId,
            lastUpdateDatetime: r.updateDate,
          })),
        };
        const messageText = `選択されたSSCCを削除します <small>(${deleteList.removeConditions.length}件)</small>`;

        const ok = await this.$dialog.confirm({ title: "SSCC削除", text: messageText });
        if (ok) {
          const result = await this.$store.dispatch("amazon/removeSscc", deleteList);

          let error = result.data?.header;

          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    sscc: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (errorRows.length > 0) {
                this.$refs.errorGrid.open({ title: "SSCC削除", records: errorRows });
                this.$dialog.notify.error(`SSCC削除処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`SSCC情報が削除されました (${deleteList.removeConditions.length}件)`, {
                  timeout: 2300,
                });
              }
              result.data?.contents.cartons?.forEach((row) => {
                // 修正対象行を取得
                var targetRow = this.selectionRows.find((r) => {
                  return r.cartonId == row.cartonId;
                });
                if (targetRow) {
                  // 更新値への変更
                  targetRow.sscc = null;
                  targetRow.updateDate = row.lastUpdateDatetime;
                  this.gridOptions.api.applyTransaction({ update: [targetRow] });
                }
              });
              // 連続で一括編集するときに値が変わってない対応
              this.selectionRows = this.gridOptions.api.getSelectedRows();
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("ShippingInstruction::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onCsvExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `Amazon出荷指示.csv`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    onIssueSscc() {
      this.onSearchClick();
      this.ssccDialog = false;
    },
    shipDateFromRules(value) {
      if (value == null || this.searchModel.shipDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.shipDateTo)) return "出荷日Toより前の日付を指定してください";
      return true;
    },
    shipDateToRules(value) {
      if (value == null || this.searchModel.shipDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.shipDateFrom)) return "出荷日Fromより後の日付を指定してください";
      return true;
    },
  },
};
</script>
