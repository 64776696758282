<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">取り込み入力</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">取り込み確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="3">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form style="height: 300px" ref="importForm" v-model="validImportForm" lazy-validation>
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <v-file-input
                      label="Excelファイルをアップロードしてください"
                      v-model="file"
                      :rules="[rules.required, rules.isExcel]"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="12">
                    <simple-file-drop v-model="file" style="height: 145px"></simple-file-drop>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onImport()">取り込み</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <p>取り込み件数：{{ excelRecords.length }}件</p>
            <div style="height: 300px">
              <ag-grid-vue
                :gridOptions="gridOptions"
                :rowData="excelRecords"
                class="ag-theme-alpine"
                style="height: 100%"
                :alwaysShowHorizontalScroll="true"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onBack()">戻る</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div style="height: 300px">
              <p>{{ excelRecords.length }}件の取り込みが完了しました。</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete()">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="importErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="新商品情報取込確認"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { DateColumn, NumericColumn } from "../../models/ag-grid/columnTypes";
import DialogBar from "../common/DialogBar.vue";
import SimpleFileDrop from "../common/SimpleFileDrop.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import FileUtils from "../../utils/FileUtils";

export default {
  name: "NewProductImport",
  components: {
    AgGridVue,
    DialogBar,
    SimpleFileDrop,
    ErrorGridDialog,
  },
  data: function () {
    return {
      step: 1,
      file: null,
      errorRows: [],
      errorColmuns: [
        { headerName: "行番号", field: "rowNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      validImportForm: null,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        releaseDate: Validation.releaseDate,
        isJancode: Validation.isJancode,
        isExcel: Validation.isExcel,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
      },
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: true,
          editable: false,
        },
        columnTypes: {
          dpDateColumn: DateColumn,
          dpNumericColumn: NumericColumn,
        },
        columnDefs: [
          { headerName: "ASIN", field: "columnA", pinned: "left" },
          { headerName: "JANCODE", field: "columnB" },
          { headerName: "Title", field: "columnC" },
          { headerName: "Vendor Code", field: "columnD" },
          { headerName: "締切日", field: "columnE" },
          { headerName: "発売日", field: "columnF" },
          { headerName: "縦(cm)", field: "columnG" },
          { headerName: "横(cm)", field: "columnH" },
          { headerName: "高さ(cm)", field: "columnI" },
          { headerName: "予約数", field: "columnJ" },
          { headerName: "分割欄", field: "columnK" },
          { headerName: "締切日（YYYY/MM/DD）", field: "columnL" },
          { headerName: "発売日（YYYY/MM/DD）", field: "columnM" },
          { headerName: "縦(cm)", field: "columnN" },
          { headerName: "横(cm)", field: "columnO" },
          { headerName: "高さ(cm)", field: "columnP" },
          { headerName: "発売中止", field: "columnQ" },
        ],
        rowSelection: false,
        suppressRowClickSelection: true,
        suppressCellSelection: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
      },
      excelRecords: [],
      importNumber: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onImport() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const excelArrayBuffer = await FileUtils.readAsArrayBuffer(this.file);
          const excelFile = XLSX.readFile(excelArrayBuffer, { type: "buffer" });
          //const sheetNames = excelFile.SheetNames;
          const excelText = excelFile.Sheets["新商品情報"];
          this.excelRecords = [];
          let index = 1;
          let maxRecords = 0;
          for (let cell in excelText) {
            if (cell.search(/^A[0-9]/) !== -1) {
              maxRecords++;
            }
          }
          for (let i = 3; i <= maxRecords; i++) {
            let jancode = excelText[`B${i}`] ? excelText[`B${i}`].w : "";
            let product = null;
            console.log(jancode);
            if (jancode) {
              product = await this.getProductData(jancode);
              console.log(product);
            }

            this.excelRecords.push({
              rowNumber: index,
              columnA: excelText[`A${i}`] ? excelText[`A${i}`].w : "",
              columnB: excelText[`B${i}`] ? excelText[`B${i}`].w : "",
              columnC: product?.productName ? product.productName : "",
              columnD: excelText[`D${i}`] ? excelText[`D${i}`].w : "",
              columnE: excelText[`E${i}`] ? excelText[`E${i}`].w : null,
              columnF: excelText[`F${i}`] ? excelText[`F${i}`].w : null,
              columnG: excelText[`G${i}`] ? excelText[`G${i}`].w : "",
              columnH: excelText[`H${i}`] ? excelText[`H${i}`].w : "",
              columnI: excelText[`I${i}`] ? excelText[`I${i}`].w : "",
              columnJ: excelText[`J${i}`] ? excelText[`J${i}`].w : null,
              columnK: excelText[`K${i}`] ? excelText[`K${i}`].w : "",
              columnL: excelText[`L${i}`] ? excelText[`L${i}`].w : null,
              columnM: excelText[`M${i}`] ? excelText[`M${i}`].w : null,
              columnN: excelText[`N${i}`] ? excelText[`N${i}`].w : "",
              columnO: excelText[`O${i}`] ? excelText[`O${i}`].w : "",
              columnP: excelText[`P${i}`] ? excelText[`P${i}`].w : "",
              columnQ: excelText[`Q${i}`] ? excelText[`Q${i}`].w : "",
            });
            index++;
          }
          if (this.validateRecords(this.excelRecords)) {
            this.step++;
          }
          console.log("NewProductImport::onImport", this.excelRecords);
        } catch (error) {
          console.error("NewProductImport::onImport", error);
          this.$dialog.notify.error(`アップロードファイルの読み込みに失敗しました。`, { timeout: 2300 });
          // return this.redirectError();
        } finally {
          this.loadingOff();
        }
      }
    },
    async onSubmit() {
      var requestRecords = [];
      this.errorRows = [];
      this.loadingOn();
      var isValid = true;
      this.excelRecords.forEach((record) => {
        var error = this.validateRow(record);
        if (error != true) {
          this.errorRows.push({
            rowNumber: record.rowNumber,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record));
        }
      });
      if (isValid) {
        try {
          this.errorRows = [];
          this.importNumber = null;
          // 1000件毎にスライス
          const sliceByNumber = (array, number) => {
            const length = Math.ceil(array.length / number);
            return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
          };
          let requestRecordsSplit = sliceByNumber(requestRecords, 1000);
          for (let i = 0; i < requestRecordsSplit.length; i++) {
            var param = {
              excelFile: this.file.name,
              newProducts: requestRecordsSplit[i],
              importNumber: this.importNumber,
            };
            const response = await this.$store.dispatch("amazon/importProductlist", param);
            console.log("NewProductImport::onSubmit", this.response);
            let error = response.data?.header;
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
                this.importNumber = response.data.contents.record.importNumber;
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    this.errorRows.push({
                      rowNumber: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                break;
              default:
                this.redirectError();
                break;
            }
          }

          if (this.errorRows.length > 0) {
            this.$refs.importErrorGrid.open({ records: this.errorRows });
          } else {
            this.$refs.importErrorGrid.close();
            this.step++;
            this.$dialog.notify.info(`新商品情報の取り込みが完了しました。 (取込番号：${this.importNumber})`, {
              timeout: 2300,
            });
          }
        } catch (error) {
          console.error("NewProductImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.importErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`取込データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
        this.loadingOff();
      }
    },
    async getProductData(value) {
      let productResponse;
      if (value >= 8) {
        try {
          productResponse = await this.$store.dispatch("product/search", {
            janCode: value,
          });
          switch (productResponse.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              if (productResponse.data.contents.products.length > 0) {
                const product = productResponse.data.contents.products[0];
                return product;
              }
              return null;
            default:
              return this.redirectError();
          }
        } catch (error) {
          console.error("DetailImport::getProductData", error);
          this.apiRequestError(error);
        }
      }
    },
    onComplete(canceled) {
      this.$refs.importForm.resetValidation();
      this.$emit("complete", canceled);
      this.step = 1;
      this.file = null;
      //
    },
    validate() {
      const isValid = this.$refs.importForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      } else {
        this.$refs.importForm.resetValidation();
      }
      return isValid;
    },
    validateRecords(records) {
      let isValid = true;
      if (records.length === 0) {
        this.$dialog.notify.error(`データがありません`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // ASIN
      this.setValidMessage(this.rules.required(row.columnA), "ASIN", messages);
      this.setValidMessage(this.rules.maxLength(10)(row.columnA), "ASIN", messages);
      // JANCODE
      this.setValidMessage(this.rules.required(row.columnB), "JANCODE", messages);
      this.setValidMessage(this.rules.isNumber(row.columnB), "JANCODE", messages);
      this.setValidMessage(this.rules.isJancode(row.columnB), "JANCODE", messages);
      // Title APIから取得するため存在チェックのみとする。
      this.setValidMessage(this.rules.required(row.columnC), "Title", messages);
      //this.setValidMessage(this.rules.maxByteLengthSjis(60)(row.columnC), "Title", messages);
      //this.setValidMessage(this.rules.maxLength(200)(row.columnC), "Title", messages);
      // Vendor Code
      this.setValidMessage(this.rules.maxLength(12)(row.columnD), "Vendor Code", messages);
      // 締切日
      this.setValidMessage(this.isDate(row.columnE), "締切日", messages);
      // 発売日
      this.setValidMessage(this.rules.releaseDate(row.columnF), "発売日", messages);
      // 縦(cm)
      this.setValidMessage(this.isDecimal(row.columnG), "縦(cm)", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnG), "縦(cm)", messages);
      // 横(cm)
      this.setValidMessage(this.isDecimal(row.columnH), "横(cm)", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnH), "横(cm)", messages);
      // 高さ(cm)
      this.setValidMessage(this.isDecimal(row.columnI), "高さ(cm)", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnI), "高さ(cm)", messages);
      // 予約数
      this.setValidMessage(this.rules.isNumber(row.columnJ), "予約数", messages);
      this.setValidMessage(this.isDecimal(row.columnJ), "予約数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.columnJ), "予約数", messages);
      // 分割欄
      // this.setValidMessage(this.rules.maxLength(12)(row.columnK), "分割欄", messages);
      // 締切日(YYYY/MM/DD)
      this.setValidMessage(this.isDate(row.columnL), "締切日(YYYY/MM/DD)", messages);
      // 発売日(YYYY/MM/DD)
      this.setValidMessage(this.rules.releaseDate(row.columnM), "発売日(YYYY/MM/DD)", messages);
      // 縦(cm)
      this.setValidMessage(this.isDecimal(row.columnN), "縦(cm)", messages);
      // 横(cm)
      this.setValidMessage(this.isDecimal(row.columnO), "横(cm)", messages);
      // 高さ(cm)
      this.setValidMessage(this.isDecimal(row.columnP), "高さ(cm)", messages);
      // 発売中止
      // this.setValidMessage(this.rules.required(row.columnQ), "発売中止", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    stringFormat(value) {
      if (value == null || value == "") return "";
      return String(value);
    },
    numberFormat(value) {
      if (value == null || value == "") return null;
      return Number(value);
    },
    parseFloatFormat(value) {
      if (value == null || value == "") return null;
      return parseFloat(value);
    },
    stringDateFormat(value) {
      if (value == null || value == "") return "";
      return moment(new Date(value)).format("YYYY-MM-DD");
    },
    isDate(value) {
      if (!value) return true;
      if (!moment(new Date(value), "YYYY/MM/DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isDecimal(value) {
      if (value == null || value == "") return true;
      let stringArray = String(value).split(".");
      let before = stringArray[0];
      let after = stringArray[1];
      if (before.length >= 7) return "整数値を7桁以内で入力してください";
      if (after != null && after.length >= 4) return "少数値を4桁以内で入力してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    requestFormat(row) {
      return {
        asin: this.stringFormat(row.columnA),
        jancode: this.stringFormat(row.columnB),
        productName: "",
        vendorCd: this.stringFormat(row.columnD),
        orderClosingDate: this.stringDateFormat(row.columnE),
        releaseDate: this.stringFormat(row.columnF),
        depth: this.parseFloatFormat(row.columnG),
        width: this.parseFloatFormat(row.columnH),
        height: this.parseFloatFormat(row.columnI),
        reservationQuantity: this.parseFloatFormat(row.columnJ),
        bunbetu: this.stringFormat(row.columnK),
        orderClosingDate2: this.stringDateFormat(row.columnL),
        releaseDate2: this.stringFormat(row.columnM),
        pkgSizeDepth2: this.parseFloatFormat(row.columnN),
        pkgSizeWidth2: this.parseFloatFormat(row.columnO),
        pkgSizeHeight2: this.parseFloatFormat(row.columnP),
        hatubaityuusi: this.stringFormat(row.columnQ),
      };
    },
    onBack() {
      this.$refs.importForm.resetValidation();
      this.file = null;
      this.step--;
    },
  },
};
</script>
