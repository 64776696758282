<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-archive</v-icon>梱包指示</v-app-bar-title>
          <v-spacer></v-spacer>
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels accordion tile>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span><v-icon>mdi-archive</v-icon>確定済みカートン</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-toolbar flat dense>
                <v-spacer></v-spacer>
                <tooltip-icon-button
                  v-if="allowedAction(['C080302'])"
                  :disabled="selectionCartons.length === 0"
                  icon="mdi-table-headers-eye"
                  @click="onPreview"
                  >内容確認</tooltip-icon-button
                >
                <tooltip-icon-button
                  v-if="allowedAction(['C080303'])"
                  :disabled="selectionCartons.length === 0"
                  icon="mdi-keyboard-return"
                  @click="onCancel"
                  >確定取消</tooltip-icon-button
                >
              </v-toolbar>
              <v-col :style="gridStyle">
                <ag-grid-vue
                  :gridOptions="gridOptions"
                  :rowData="cartonRecords"
                  class="ag-theme-alpine"
                  style="height: 100%"
                  @selection-changed="onSelectionChanged"
                ></ag-grid-vue>
              </v-col>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="activeTab">
          <v-tab style="text-transform: none" href="#c_source">未指示一覧</v-tab>
          <v-tab
            style="text-transform: none"
            :href="`#c_${carton.id}`"
            :key="carton.id"
            v-for="carton in currentCartons"
          >
            {{ carton.name }}<v-icon small v-if="carton.preview">mdi-pencil-off</v-icon>
          </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="activeTab">
          <v-tab-item value="c_source">
            <carton-grid
              ref="CartonGrid"
              :height="gridStyle2.height"
              identifier="source"
              v-model="sourceRecords"
              @transfer="onTransfer(null, $event)"
              hide-commit
              hide-purge
              @grid-ready="onGridReady"
            ></carton-grid>
          </v-tab-item>
          <v-tab-item :key="carton.id" :value="`c_${carton.id}`" v-for="(carton, index) in currentCartons">
            <carton-grid
              :height="gridStyle2.height"
              :suffix="index"
              :preview="carton.preview"
              v-model="carton.records"
              @transfer="onTransfer(index, $event)"
              @commit="onCommit(index, $event)"
              @purge="onPurge(index, $event)"
              @editor="onEditor(index, $event)"
              @close="onClose(index, $event)"
            ></carton-grid>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-dialog :value="transferDialog" max-width="400px" persistent>
      <v-card v-if="transferDialog">
        <v-card-title>
          <span class="headline"><v-icon>mdi-archive-star</v-icon>カートン指定</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="transferForm" v-model="validTransferForm" @submit.prevent lazy-validation>
            <v-container>
              <v-row dense>
                <v-col>
                  <v-radio-group v-model="transferModel.createNew" row>
                    <v-radio label="追加して移動" :value="true"></v-radio>
                    <v-radio label="カートン移動" :value="false" :disabled="editableCartons.length === 0"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="transferModel.createNew">
                  <v-text-field
                    label="新しいカートン名"
                    v-model="transferModel.newCartonName"
                    :rules="[rules.newCartonNameRule, rules.maxLength(50)]"
                    clearable
                    filled
                    dense
                    @keyup.enter="onTransferSubmit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-else>
                  <v-select
                    label="移動先のカートン名"
                    :items="editableCartons"
                    item-text="name"
                    item-value="id"
                    v-model="transferModel.selectedCarton"
                    :rules="[rules.cartonNameRule, rules.maxLength(50)]"
                    filled
                    dense
                    attach
                    @keyup.enter="onTransferSubmit"
                  ></v-select>
                </v-col>
                <v-col v-if="transferModel.amountFlg">
                  <v-text-field
                    label="数量"
                    v-model="cartonAmount"
                    :rules="[rules.required, rules.isNumber, rules.isMinNumber(1), rules.cartonAmountRules]"
                    filled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="secondary" @click="onTransferCancel">キャンセル</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onTransferSubmit">確定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-grid-dialog
      ref="errorGrid"
      width="80%"
      height="80%"
      title="カートン確定"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import CartonGrid from "../../components/amazon/CartonGrid.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "CartonInstruction",
  components: {
    AgGridVue,
    CartonGrid,
    TooltipIconButton,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridStyle2: { height: "95%" },
      gridApi: null,
      columnApi: null,
      activeTab: null,
      selectedPageSize: 10,
      cartonAmount: 0,
      sourceRecords: [],
      currentCartons: [],
      transferDialog: false,
      transferModel: {
        srcCarton: null,
        movingRows: null,
        commitFunc: null,
        createNew: null,
        newCartonName: null,
        selectedCarton: null,
      },
      validTransferForm: null,
      gridOptions: {
        columnTypes: {
          dpDateColumn: DateColumn,
          dpNumericColumn: NumericColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
            width: 55,
          },
          { headerName: "出荷日", field: "shipDate", filter: "agDateColumnFilter", type: "dpDateColumn", width: 125 },
          { headerName: "倉庫", field: "delivery1", width: 135 },
          { headerName: "カートン名", field: "cartonName", width: 185 },
          {
            headerName: "レコード数",
            field: "recordCount",
            type: "dpNumericColumn",
            width: 120,
          },
          { headerName: "確定日", field: "fixDate", filter: "agDateColumnFilter", type: "dpDateColumn", width: 300 },
          { headerName: "確定者", field: "fixUser", width: 300 },
          { headerName: "カートンID", field: "cartonId", hide: true },
          {
            headerName: "更新日",
            field: "updateDate",
            filter: "agDateColumnFilter",
            type: "dpDateColumn",
            hide: true,
          },
        ],
        rowHeight: 30,
        rowSelection: "multiple",
        rowMultiSelectWithClick: false,
        suppressCellSelection: true,
        suppressColumnVirtualisation: true,
        pagination: true,
        paginationPageSize: 5,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
      },
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      cartonRecords: [],
      selectionCartons: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        maxLength: Validation.maxLength,
        cartonAmountRules: (value) => this.cartonAmountRules(value),
        newCartonNameRule: (value) => this.newCartonNameRules(value),
        cartonNameRule: (value) => this.cartonNameRules(value),
      },
    };
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.init();
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize * 0.5 + "px";
    this.gridStyle2.height = this.gridHeightSize - 160 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridApi?.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value * 0.5 + "px";
      this.gridStyle2.height = value - 160 + "px";
    },
  },
  computed: {
    editableCartons() {
      return this.currentCartons.filter((c) => !c.preview);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
      this.$refs.CartonGrid.clearFilters();
    },
    async init() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("amazon/searchPacking");
        console.log("CartonInstruction::init", response);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        this.sourceRecords = result.shippingInstructions;
        this.cartonRecords = result.cartons;
        if (this.gridApi) this.gridApi.setRowData(this.cartonRecords);
        if (this.$refs.transferForm) this.$refs.transferForm.resetValidation();
      } finally {
        this.loadingOff();
      }
    },
    onTransfer(index, { rows, commit, amountFlg }) {
      let cnt = 1;
      const row = rows[0];
      let newCartonName = row.delivery1 ? row.delivery1 : null;
      // 確定済みカートン
      this.cartonRecords.forEach((cartonRecord) => {
        console.log("cartonRecord:", cartonRecord);
        if (cartonRecord.cartonName.includes(newCartonName)) {
          cnt++;
        }
      });
      // 新規で作成されているカートン（タブ）
      this.currentCartons.forEach((currentCarton) => {
        console.log("currentCarton:", currentCarton);
        if (currentCarton.name.includes(newCartonName)) {
          cnt++;
        }
      });
      newCartonName = (newCartonName ? newCartonName : "") + `(${("000" + cnt).slice(-3)})`;
      this.transferModel = {
        originalCarton: index,
        movingRows: rows,
        commitFunc: commit,
        createNew: true,
        newCartonName: newCartonName,
        selectedCarton: null,
        amountFlg: amountFlg,
        orderQuantity: rows[0].orderQuantity,
      };
      this.cartonAmount = this.transferModel.orderQuantity;
      this.transferDialog = true;
    },
    onTransferSubmit() {
      if (this.validate()) {
        const model = this.transferModel;
        let currentCartonRecords = JSON.parse(JSON.stringify(model.movingRows));
        if (model.amountFlg) currentCartonRecords[0].orderQuantity = this.cartonAmount;
        if (model.createNew) {
          const uniqueId = new Date().getTime().toString();
          this.currentCartons.push({
            name: model.newCartonName,
            records: currentCartonRecords,
            id: uniqueId,
            updateDate: null,
            preview: false,
          });
        } else {
          const carton = this.currentCartons.find((carton) => carton.id === model.selectedCarton);
          const addCartons = [];
          for (let i = 0; i < currentCartonRecords.length; i++) {
            const shippingRecord = carton.records.find(
              (record) => record.shippingInstructionId === currentCartonRecords[i].shippingInstructionId
            );
            if (shippingRecord) {
              shippingRecord.orderQuantity =
                Number(shippingRecord.orderQuantity) + Number(currentCartonRecords[i].orderQuantity);
            } else {
              addCartons.push(currentCartonRecords[i]);
            }
          }
          carton.records = [...carton.records, ...addCartons];
        }
        model.commitFunc(this.cartonAmount);
        this.transferDialog = false;
      }
    },
    onTransferCancel() {
      this.transferDialog = false;
    },
    async onPurge(index) {
      const deleteRow = this.currentCartons[index];
      const ok = await this.$dialog.confirm({
        title: "カートン削除",
        text: `カートン「${deleteRow.name}」を削除します`,
      });
      if (ok) {
        const addCartons = [];
        for (let i = 0; i < deleteRow.records.length; i++) {
          const shippingRecord = this.sourceRecords.find(
            (record) => record.shippingInstructionId === deleteRow.records[i].shippingInstructionId
          );
          if (shippingRecord) {
            shippingRecord.orderQuantity =
              Number(shippingRecord.orderQuantity) + Number(deleteRow.records[i].orderQuantity);
          } else {
            addCartons.push(deleteRow.records[i]);
          }
        }
        this.sourceRecords = [...this.sourceRecords, ...addCartons];
        this.$refs.CartonGrid.updateGrid();
        this.currentCartons.splice(index, 1);
      }
    },
    async onCommit(index) {
      const currentCarton = this.currentCartons[index];
      const ok = await this.$dialog.confirm({
        title: "カートン確定",
        text: `カートン「${currentCarton.name}」を確定します`,
      });
      if (ok) {
        try {
          this.loadingOn();
          this.selectionRows = [];
          let shippingInstructions = [];
          currentCarton.records.forEach((record) => {
            shippingInstructions.push({
              shippingInstructionId: record.shippingInstructionId,
              quantity: Number(record.orderQuantity),
              lastUpdateDatetime: record.updateDate,
            });
          });
          let carton = {
            cartonName: currentCarton.name,
            shippingInstructions: shippingInstructions,
          };
          const response = await this.$store.dispatch("amazon/comfirmPacking", { carton });
          console.log("CartonInstruction::onCommit", response);
          let errorRows = [];
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              var result = response.data.contents;
              this.cartonRecords.push(result.carton);
              // shippingInstructions.forEach((shippingInstruction) => {
              //   this.sourceRecords = this.sourceRecords.filter(
              //     (c) => c.shippingInstructionId !== shippingInstruction.shippingInstructionId
              //   );
              // });
              // if (this.gridApi) this.gridApi.setRowData(this.cartonRecords);
              this.$dialog.notify.info("カートンを確定しました", { timeout: 2300 });
              this.$refs.errorGrid.close();
              this.currentCartons.splice(index, 1);
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var error = response.data?.header;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    shippingInstructionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              } // エラー表示
              if (errorRows.length > 0) {
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`カートン確定の登録処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$refs.errorGrid.close();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } finally {
          this.loadingOff();
        }
      }
    },
    async onEditor(index) {
      const carton = this.currentCartons[index];
      const ok = await this.$dialog.confirm({
        title: "カートン編集",
        text: `カートン「${carton.name}」の確定を取り消して編集を開始しますか`,
      });
      if (ok) {
        carton.preview = false;
        try {
          this.loadingOn();
          this.selectionRows = [];
          const response = await this.$store.dispatch("amazon/cancelPacking", {
            cartonId: carton.id,
            lastUpdateDatetime: carton.updateDate,
          });
          console.log("CartonInstruction::onEditor", response);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              var result = response.data.contents;
              this.currentCartons[index].records = result.shippingInstructions;
              this.cartonRecords = this.cartonRecords.filter((c) => c.cartonId !== carton.id);
              if (this.gridApi) this.gridApi.setRowData(this.cartonRecords);
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var errorRows = [];
              var error = response.data?.header;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    shippingInstructionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              } // エラー表示
              if (errorRows.length > 0) {
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`カートン取消処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$refs.errorGrid.close();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } finally {
          this.loadingOff();
        }
      }
    },
    onClose(index) {
      console.log("currentCartons:", this.currentCartons[index]);
      this.selectionCartons.forEach((c) => {
        console.log("c.cartonId:", c);
        if (c.cartonId === this.currentCartons[index].id) {
          console.log("match?");
          c.preview = false;
        }
      });
      this.currentCartons.splice(index, 1);
    },
    async onCancel() {
      for (let i = 0; i < this.selectionCartons.length; i++) {
        if (this.selectionCartons[i].preview) {
          this.$dialog.notify.error(`内容参照中のカートンが含まれています。ご確認ください。`, {
            timeout: 2300,
          });
          return;
        }
      }
      const ok = await this.$dialog.confirm({
        title: "カートン確定取消",
        text: `選択されたカートンの確定を取消します`,
      });
      if (ok) {
        for (let i = 0; i < this.selectionCartons.length; i++) {
          const removedCartons = [];
          const carton = this.selectionCartons[i];
          removedCartons.push(carton.cartonId);
          try {
            this.loadingOn();
            this.selectionRows = [];
            const response = await this.$store.dispatch("amazon/cancelPacking", {
              cartonId: carton.cartonId,
              lastUpdateDatetime: carton.updateDate,
            });
            console.log("CartonInstruction::onCancel", response);
            switch (response.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                var result = response.data.contents;
                var addCartons = [];
                for (let i = 0; i < result.shippingInstructions.length; i++) {
                  const shippingRecord = this.sourceRecords.find(
                    (record) => record.shippingInstructionId === result.shippingInstructions[i].shippingInstructionId
                  );
                  if (shippingRecord) {
                    shippingRecord.orderQuantity =
                      Number(shippingRecord.orderQuantity) + Number(result.shippingInstructions[i].orderQuantity);
                  } else {
                    addCartons.push(result.shippingInstructions[i]);
                  }
                }
                this.sourceRecords = this.sourceRecords.concat(addCartons);
                this.$refs.CartonGrid.updateGrid();
                this.cartonRecords = this.cartonRecords.filter((c) => !removedCartons.includes(c.cartonId));
                //this.gridApi.setRowData(this.cartonRecords);
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                var errorRows = [];
                var error = response.data?.header;
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      shippingInstructionId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                } // エラー表示
                if (errorRows.length > 0) {
                  this.$refs.errorGrid.open({ records: errorRows });
                  this.$dialog.notify.error(`カートン取消処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$refs.errorGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } finally {
            this.loadingOff();
          }
        }
      }
    },
    async onPreview() {
      for (let i = 0; i < this.selectionCartons.length; i++) {
        const carton = this.selectionCartons[i];
        if (this.currentCartons.some((c) => c.id === carton.cartonId)) {
          continue;
        }
        try {
          this.loadingOn();
          this.selectionRows = [];
          const response = await this.$store.dispatch("amazon/referencePacking", { cartonId: carton.cartonId });
          console.log("CartonInstruction::onPreview", response);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              var result = response.data.contents;
              this.currentCartons.push({
                name: carton.cartonName,
                // records: result.shippingInstructions,
                records: result.shipping,
                id: carton.cartonId,
                updateDate: carton.updateDate,
                preview: true,
              });
              carton.preview = true;
              if (this.gridApi) this.gridApi.applyTransaction({ update: this.cartonRecords });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = this.setError(response.data?.header.messages);
              if (message != "") {
                this.$dialog.warning({
                  title: `カートン内容参照`,
                  text: `${message} 削除された可能性があります。`,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } finally {
          this.loadingOff();
        }
      }
    },
    onSelectionChanged() {
      this.selectionCartons = this.gridOptions.api.getSelectedRows();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.init(this.inputModel);
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
    },
    validate() {
      const isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    newCartonNameRules(value) {
      if (this.transferModel.createNew) {
        let result = true;
        if (!value) return "カートン名を入力してください";
        this.cartonRecords.forEach((cartonRecord) => {
          if (String(cartonRecord.cartonName) === String(value)) {
            result = false;
            return;
          }
        });
        this.currentCartons.forEach((currentCarton) => {
          if (String(currentCarton.name) === String(value)) {
            result = false;
            return;
          }
        });
        if (!result) {
          return "同一のカートン名があります";
        }
      }
      return true;
    },
    cartonNameRules(value) {
      if (!this.transferModel.createNew) {
        if (!value) return "カートン名を入力してください";
      }
      if (this.activeTab.endsWith(value)) {
        return "同一カートンには移動できません";
      }
      return true;
    },
    cartonAmountRules(value) {
      if (!value && Number(value) !== 0) return true;
      if (Number(value) > this.transferModel.orderQuantity) {
        return "元数量を超えています";
      }
      return true;
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      var isMessage = false;
      if (!isMessage) {
        Object.keys(responseMessage).forEach((key) => {
          messages.push(responseMessage[key].join("<br>"));
        });
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
  },
};
</script>
